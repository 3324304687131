
@import "assets/layout/styles/layout/layout.scss";
@import "assets/statuses";
@import "../node_modules/primeicons/primeicons.css";
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.css";

html, body {
  height: 100%;
}

body {
  margin: 0;
  min-height: 100%;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 400;
  color: #333c40;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
}

.map-container {
  width: 100% !important;
  height: 600px !important;
  position: relative;
}

tj-invoice-grid {
  margin-top: 30px;
}

::ng-deep p-dialog .ui-dialog {
  overflow: scroll;
  max-height: 100%;
}

.mask-style-dialog {
  z-index:999;
}

@media screen and (min-width: 960px) {
  .dialog {
    max-width: 60% !important;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.mr-right-1 {
  margin-right: 0.25em;
}

.print-only {
  display: none !important;
}

.print-only-inline {
  display: none !important;
}

@media print {
  .no-print *:not(.printable) {
    display: none !important;
  }
  .print-only {
    display: block !important;
  }
  .print-only-inline {
    display: inline !important;
  }
}

input,
textarea,
tj-field p-autoComplete > span,
tj-field p-dropdown > div {
  width: 100%;
}

p-panel {
  margin-bottom: 10px;
}

.tj-field {
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.p-calendar, .p-autocomplete {
  display: flex !important;
}

p-calendar, p-autocomplete, .p-autocomplete-multiple-container {
  width: 100%;
}

.tj-title {
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
}

input[type=radio], input[type=checkbox] {
  width: auto;
}

.p-orderlist .p-orderlist-controls {
  display: none;
}

//todo refactor
//workaround: when only one invoice is present in invoices page and when trying to change status from the dropdown
tj-invoice-list .p-datatable > .p-datatable-wrapper {
  overflow: visible !important;;
}

a.list-link:visited,
a.list-link:active{
  color: #0000ee;
}

.private {
  display: inline-block;
  border: 1px solid #ff4d00;
  border-radius: 3px;
  padding: 2px 3px;
  color: #ff4d00;
}
