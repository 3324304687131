.status-pill {
  display: block;
  text-align: center;
  border-radius: 10rem;
  color: #ffffff;
  font-size: 0.9rem;
  font-weight: 700;
  padding: 0.25rem 0.4rem;
}

.status-new {
  background: var(--red-500) !important;
}
.status-customer-will-call {
  background: var(--yellow-500) !important;
}
.status-canceled {
  background: var(--gray-500) !important;
}
.status-assigned {
  background: var(--blue-300) !important;
}
.status-scheduled {
  background: var(--blue-800) !important;
}
.status-rescheduled {
  background: var(--purple-500) !important;
}
.status-complete {
  background: var(--green-600) !important;
}
.status-verified {
  background: var(--gray-900) !important;
}
.status-incomplete {
  background: var(--orange-500) !important;
}
.status-invoiced {
  background: var(--orange-800) !important;
}
