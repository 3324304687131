.layout-sidebar-white {
    --d-sidebar-bg-color:#ffffff;
    --d-sidebar-bg-color-alt:#ffffff;
    --d-sidebar-border:0 none;
    --d-app-name-color:#495057;
    --d-menu-separator-border: 1px solid #dee2e6;
    --d-menuitem-root-text-color: #6c757d;
    --d-menuitem-text-color: #495057;
    --d-menuitem-hover-bg: #e9ecef;
    --d-menuitem-active-bg: #ffffff;
    --d-menuitem-text-active-color:#495057;
    --d-menuitem-focus-shadow: 0 0 0 0.2rem rgba(255,255,255,0.1);
}