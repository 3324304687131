.ng-select {
    margin: 0 0 0.85714286rem;
}
.ng-select.ng-select-opened>.ng-select-container{
    background:#fff;
    border-color:#b3b3b3 #ccc #d9d9d9
}
.ng-select.ng-select-opened>.ng-select-container:hover{
    box-shadow:none
}
.ng-select.ng-select-opened>.ng-select-container .ng-arrow{
    top:-2px;
    border-color:transparent transparent #999;
    border-width:0 5px 5px
}
.ng-select.ng-select-opened>.ng-select-container .ng-arrow:hover{
    border-color:transparent transparent #666
}
.ng-select.ng-select-opened.ng-select-bottom>.ng-select-container{
    border-bottom-right-radius:0;
    border-bottom-left-radius:0
}
.ng-select.ng-select-opened.ng-select-top>.ng-select-container{
    border-top-right-radius:0;
    border-top-left-radius:0
}
.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container{
    border-color:#007eff;
    box-shadow:inset 0 1px 1px rgba(0,0,0,0.075),0 0 0 3px rgba(0,126,255,0.1)
}
.ng-select.ng-select-disabled>.ng-select-container{
    background-color:#f9f9f9
}
.ng-select .ng-has-value .ng-placeholder{
    display:none
}
.ng-select .ng-select-container{
    background-color: #fefefe;
    border-radius: 0px;
    border: 1px solid #cacaca;
    min-height: 2.28571429rem;
    align-items:  center;
}

.ng-select .ng-select-container:hover{
    box-shadow:0 1px 0 rgba(0,0,0,0.06)
}
.ng-select .ng-select-container .ng-value-container{
    align-items:center;
    padding-left: 3px
}
.ng-select .ng-select-container .ng-value-container .ng-placeholder{
    color:#aaa
}
.ng-select.ng-select-single .ng-select-container{
    margin: 0 0 0.85714286rem;
    padding: 0.42857143rem;
    height: 2.28571429rem;
}
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input{
    padding-top: 8px;
}
.ng-select.ng-select-multiple.ng-select-disabled>.ng-select-container .ng-value-container .ng-value{
    background-color:#f9f9f9;
    border:1px solid #e3e3e3
}
.ng-select.ng-select-multiple.ng-select-disabled>.ng-select-container .ng-value-container .ng-value .ng-value-label{
    padding:0px 5px
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container{
    padding-left:7px;
    padding-top:5px
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value{
    font-size:0.9em;
    margin-right:5px;
    margin-bottom:5px;
    background-color:#f5faff;
    border-radius:2px;
    border:1px solid #c2e0ff
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled{
    background-color:#f9f9f9;
    border:1px solid #e3e3e3
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled .ng-value-label{
    padding-left:5px
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label{
    display:inline-block;
    padding:0px 5px 0px 1px
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon{
    display:inline-block;
    padding:0px 5px
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover{
    background-color:#d8eafd
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left{
    border-right:1px solid #c2e0ff
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.right{
    border-left:1px solid #c2e0ff
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input{
    margin-top: -5px;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input input {
    margin: 0;
    padding: 0;
    height: 25px;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder{
    top:5px;
    padding-bottom:5px;
    padding-left:3px
}
.ng-select .ng-clear-wrapper{
    color:#999
}
.ng-select .ng-clear-wrapper .ng-clear:hover{
    color:#D0021B
}
.ng-select .ng-spinner-zone{
    padding-right:5px;
    padding-top:5px
}
.ng-select .ng-arrow-wrapper{
    padding-right:5px;
    width:25px
}
.ng-select .ng-arrow-wrapper .ng-arrow{
    border-color:#999 transparent transparent;
    border-style:solid;
    border-width:5px 5px 2.5px
}
.ng-select .ng-arrow-wrapper .ng-arrow:hover{
    border-top-color:#666
}
.ng-dropdown-panel{
    background-color:#fff;
    border:1px solid #ccc;
    box-shadow:0 1px 0 rgba(0,0,0,0.06)
}
.ng-dropdown-panel.ng-select-bottom{
    top:100%;
    border-bottom-right-radius:4px;
    border-bottom-left-radius:4px;
    border-top-color:#e6e6e6;
    margin-top:-1px
}
.ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items .ng-option:last-child{
    border-bottom-right-radius:4px;
    border-bottom-left-radius:4px
}
.ng-dropdown-panel.ng-select-top{
    bottom:100%;
    border-top-right-radius:4px;
    border-top-left-radius:4px;
    border-bottom-color:#e6e6e6;
    margin-bottom:-1px
}
.ng-dropdown-panel.ng-select-top .ng-dropdown-panel-items .ng-option:first-child{
    border-top-right-radius:4px;
    border-top-left-radius:4px
}
.ng-dropdown-panel .ng-dropdown-header{
    border-bottom:1px solid #ccc;
    padding:5px 7px
}
.ng-dropdown-panel .ng-dropdown-footer{
    border-top:1px solid #ccc;
    padding:5px 7px
}
.ng-dropdown-panel .ng-dropdown-panel-items{
    margin-bottom:1px
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup{
    user-select:none;
    cursor:default;
    padding:8px 10px
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-select-disabled{
    color:rgba(0,0,0,0.54)
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-marked{
    background-color:#ebf5ff;
    color:#333
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected{
    color:#333;
    background-color:#f5faff;
    font-weight:600
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option{
    background-color:#fff;
    color:rgba(0,0,0,0.87);
    padding:8px 10px
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected{
    color:#333;
    background-color:#f5faff
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label{
    font-weight:600
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked{
    background-color:#ebf5ff;
    color:#333
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled{
    color:#cccccc
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child{
    padding-left:22px
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label{
    padding-right:5px;
    font-size:80%;
    font-weight:400
}
