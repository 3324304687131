@keyframes modal-in {
	from {
		background-color: transparent;
	}

	to {
		background-color: var(--maskbg);
	}
}

.modal-in {
	animation-name: modal-in;
}
